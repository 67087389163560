<template>
  <div class="setting-container">
    <div class="setting-header">
      
      <my-icon
        class="setting-close"
        :iconName="'iconxitonglan_guanbi_hei'"
        :fontSize="16"
        @click="close"
      ></my-icon>
    </div>
    <div class="setting-body">
      <div
        class="setting-nav"
        :class="$i18n.locale === 'en-US' ? 'seeting-width' : ''"
      >
        <ul class="setting-menu">
          <!-- <li class="setting-item" @click="handleNav(item, index)" :class="[index===activeIndex ? 'active' : '']"
              v-for="(item, index) in navList" :key="item.id" v-show="(item.id !== 6 && item.id !== 8) || (item.id === 8 && isMeeting) || (item.id === 6 && !isMeeting)">{{ item.name }}
          </li> -->
          <li
            class="setting-item"
            @click="handleNav(item, index)"
            :class="[index === activeIndex ? 'active' : '']"
            v-for="(item, index) in navList"
            :key="item.id"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>

      <div class="setting-content">
        <div class="setting-normal" v-show="activeType === 'normal'">
          <el-checkbox
              class="single-option"
              :class="{'single-option-s2': isMeeting}"
              v-model="hideNoVideoMember"
              @change="onCheckedNormalSetting(2)"
              >隐藏非视频参会方</el-checkbox
          >
          <el-checkbox
            v-model="showDuration"
            @change="onCheckedNormalSetting(3)"
            >{{ $t("setUp.showTime") }}</el-checkbox
          >
          <div class="common-set-line shareArguments">
            <el-checkbox
                class="shareArgumentsSetCheckbox"
                style="margin-right: 24px"
                v-model="frames"
                @change="onCheckedNormalSetting(4)"
                >{{ $t("setUp.limit") }}</el-checkbox
              >
            <div class="shareArgumentsSet">
              <div class="argumentsItem">
                <p><span>分辨率</span>：</p>
                <el-select
                  @change="resolutionChange"
                  v-model="currentSelectedResolution"
                  :disabled="!frames"
                  class="userMinSelect setting-frame"
                  size="mini"
                >
                  <el-option
                    v-for="item in Resolution"
                    :key="item"
                    :label="item"
                    :value="item"
                    >
                  </el-option>
                </el-select>
              </div>
              <div class="argumentsItem">
                <p><span>帧率</span>：</p>
                <el-select
                  @change="frameChange('shareLimitValue',currentSelectedShareFrame)"
                  v-model="currentSelectedShareFrame"
                  :disabled="!frames"
                  class="userMinSelect setting-frame"
                  size="mini"
                >

                    <el-option
                    v-for="item in frameList"
                    :key="item"
                    :label="item == 60 ? item + '(对系统要求较高)' : item"
                    :value="item"
                    >
                  </el-option>
                </el-select>
                <p>{{ $t("setUp.second") }}</p>
              </div>
              <div class="argumentsItem">
                <p><span>码率</span>：</p>
                <el-select
                  @change="frameChange('shareBitrateValue',currentSelectedShareBitrate)"
                  v-model="currentSelectedShareBitrate"
                  :disabled="!frames"
                  class="userMinSelect setting-frame"
                  size="mini"
                >

                    <el-option
                    v-for="item in shareBitrates"
                    :key="item"
                    :label="item"
                    :value="item"
                    >
                  </el-option>
                </el-select>
                <p>Kbps</p>
              </div>
            </div>
          </div>

          <!-- 语言设置 -->
          <div class="common-set-line lang" v-show="!isMeeting">
            <div class="lang-text">{{ $t("setUp.language") }}</div>

            <Lang :is-default="true" class="lang-select-box" />
          </div>
        </div>

        <!-- 设置网络检测-->
        <div class="setting-network" v-show="activeType === 'network'">
          <div class="network-test" v-if="!showNetworkResult">
            <ul>
              <li class="network-icon">
                <my-icon
                  :iconName="'iconshezhi_wangluojiance_64_lv'"
                  :fontSize="64"
                ></my-icon>
              </li>
              <li class="network-title">
                {{ $t("setUp.netDetection")
                }}<span v-if="currentNetworkProgressValue"
                  >（{{
                    currentNetworkProgressValue + "/" + networkProgressValue
                  }}）</span
                >
              </li>
              <li class="network-subtitle">
                {{ currentNetworkProgressTip || $t("setUp.pleaseDec") }}
              </li>
              <li class="network-button">
                <el-button
                  @click="startNetworkDetection"
                  :loading="networkLoading"
                  >{{ !networkLoading ? $t("setUp.start") : "" }}</el-button
                >
              </li>
            </ul>
          </div>
          <div class="network-result" v-if="showNetworkResult">
            <div class="network-result-header">
              <div class="status-icon">
                <my-icon
                  :iconName="statusIconClassName"
                  :fontSize="48"
                ></my-icon>
              </div>
              <div class="status-content">
                <div class="status-title">
                  {{ $t("setUp.networkStatus") }}{{ statusTitle }}
                </div>
                <div class="status-sub-title">
                  {{ $t("setUp.netOk") }}{{ statusTitle }}
                </div>
              </div>
              <div class="status-button">
                <el-button
                  @click="startNetworkDetection"
                  :loading="networkLoading"
                  >{{ !networkLoading ? $t("setUp.againDec") : "" }}</el-button
                >
              </div>
            </div>
            <div class="network-result-status">
              <ul>
                <!-- <li class="result-item">
                  <div class="result-name">网络权限</div>
                  <div class="result-status">正常</div>
                </li> -->
                <li class="result-item">
                  <div class="result-name">{{ $t("setUp.netConnection") }}</div>
                  <div
                    class="result-status"
                    :class="{ error: localNetworkTime <= 0 }"
                  >
                    {{
                      localNetworkTime > 0
                        ? $t("setUp.ok")
                        : $t("setUp.overTime")
                    }}
                  </div>
                </li>
                <li class="result-item">
                  <div class="result-name">
                    {{ $t("setUp.serverConnection") }}
                  </div>
                  <div
                    class="result-status"
                    :class="{ error: serverNetworkTime <= 0 }"
                  >
                    {{
                      serverNetworkTime > 0
                        ? $t("setUp.ok")
                        : $t("setUp.overTime")
                    }}
                  </div>
                </li>
                <!-- <li class="result-item">
                  <div class="result-name">域名解析耗时</div>
                  <div class="result-status">正常</div>
                </li> -->
                <li class="result-item">
                  <div class="result-name">
                    {{ $t("setUp.connectionTime") }}
                  </div>
                  <div
                    class="result-status"
                    :class="{
                      error: serverNetworkTime <= 0 || serverNetworkTime >= 300,
                    }"
                  >
                    {{
                      serverNetworkTime > 0
                        ? serverNetworkTime + "ms"
                        : $t("setUp.overTime")
                    }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 质量检测 -->
        <div
          class="setting-quality"
          v-show="isMeeting && activeType === 'quality'"
        >
          <div class="panel">
            <div class="title">
              {{ $t("meeting.nets") }}
              <div class="desc">
                {{ $t("meeting.delay") }} {{ userStats.rtt }}ms
              </div>
            </div>
            <div class="body">
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("meeting.bandwidth") }}</span>
                  <my-icon
                    class="iconwangluo_shanghang"
                    :fontSize="16"
                    :iconName="'iconwangluo_shanghang_24_hei'"
                  ></my-icon>
                  <span class="after">{{ userStats.sentBitrate }}kb/s</span>
                </div>
                <div class="item bottom">
                  <my-icon
                    class="iconwangluo_xiahang"
                    :fontSize="16"
                    :iconName="'iconwangluo_xiahang_24_hei'"
                  ></my-icon>
                  <span class="after">{{ userStats.recvBitrate }}kb/s</span>
                </div>
              </div>
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("meeting.packet") }}</span>
                  <my-icon
                    class="iconwangluo_shanghang"
                    :fontSize="16"
                    :iconName="'iconwangluo_shanghang_24_hei'"
                  ></my-icon>
                  <span class="after">{{ userStats.sentLossRate }}%</span>
                </div>
                <div class="item bottom">
                  <my-icon
                    class="iconwangluo_xiahang"
                    :fontSize="16"
                    :iconName="'iconwangluo_xiahang_24_hei'"
                  ></my-icon>
                  <span class="after">{{ userStats.recvLossRate }}%</span>
                </div>
              </div>
              <div class="box">
                <div class="item" v-show="userStats.networkType">
                  <span class="before">{{ $t("meeting.nets") }}</span>
                  <span class="after">{{ userStats.networkType }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="panel">
            <div class="title">{{ $t("setUp.audio") }}</div>
            <div class="body">
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("meeting.bitrate") }}</span>
                  <my-icon
                    class="iconwangluo_shanghang"
                    :fontSize="16"
                    :iconName="'iconwangluo_shanghang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.audioSentBitrate }}kb/s</span
                  >
                </div>
                <div class="item bottom">
                  <my-icon
                    class="iconwangluo_xiahang"
                    :fontSize="16"
                    :iconName="'iconwangluo_xiahang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.audioRecvBitrate }}kb/s</span
                  >
                </div>
              </div>
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("setUp.microphone") }}</span>
                  <span class="after">{{ userStats.micDb }}dB</span>
                </div>
              </div>
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("setUp.speaker") }}</span>
                  <span class="after">{{ userStats.speakerDb }}dB</span>
                </div>
              </div>
            </div>
          </div>

          <div class="panel">
            <div class="title">{{ $t("setUp.video") }}</div>
            <div class="body">
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("meeting.Resolution") }}</span>
                  <my-icon
                    class="iconwangluo_shanghang"
                    :fontSize="16"
                    :iconName="'iconwangluo_shanghang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.videoSentWidth }}x{{
                      userStats.videoSentHeight
                    }}</span
                  >
                </div>
                <div class="item bottom">
                  <my-icon
                    class="iconwangluo_xiahang"
                    :fontSize="16"
                    :iconName="'iconwangluo_xiahang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.videoRecvWidth }}x{{
                      userStats.videoRecvHeight
                    }}</span
                  >
                </div>
              </div>
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("meeting.frameRate") }}</span>
                  <my-icon
                    class="iconwangluo_shanghang"
                    :fontSize="16"
                    :iconName="'iconwangluo_shanghang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.videoSentFps }}{{ $t("meeting.Frame") }}</span
                  >
                </div>
                <div class="item bottom">
                  <my-icon
                    class="iconwangluo_xiahang"
                    :fontSize="16"
                    :iconName="'iconwangluo_xiahang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.videoRecvFps }}{{ $t("meeting.Frame") }}</span
                  >
                </div>
              </div>
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("meeting.bitrate") }}</span>
                  <my-icon
                    class="iconwangluo_shanghang"
                    :fontSize="16"
                    :iconName="'iconwangluo_shanghang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.videoSentBitrate }}kb/s</span
                  >
                </div>
                <div class="item bottom">
                  <my-icon
                    class="iconwangluo_xiahang"
                    :fontSize="16"
                    :iconName="'iconwangluo_xiahang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.videoRecvBitrate }}kb/s</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="panel">
            <div class="title">{{ $t("meeting.Sharing") }}</div>
            <div class="body">
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("meeting.Resolution") }}</span>
                  <my-icon
                    class="iconwangluo_shanghang"
                    :fontSize="16"
                    :iconName="'iconwangluo_shanghang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.shareSentWidth }}x{{
                      userStats.shareSentHeight
                    }}</span
                  >
                </div>
                <div class="item bottom">
                  <my-icon
                    class="iconwangluo_xiahang"
                    :fontSize="16"
                    :iconName="'iconwangluo_xiahang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.shareRecvWidth }}x{{
                      userStats.shareRecvHeight
                    }}</span
                  >
                </div>
              </div>
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("meeting.frameRate") }}</span>
                  <my-icon
                    class="iconwangluo_shanghang"
                    :fontSize="16"
                    :iconName="'iconwangluo_shanghang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.shareSentFps }}{{ $t("meeting.Frame") }}</span
                  >
                </div>
                <div class="item bottom">
                  <my-icon
                    class="iconwangluo_xiahang"
                    :fontSize="16"
                    :iconName="'iconwangluo_xiahang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.shareRecvFps }}{{ $t("meeting.Frame") }}</span
                  >
                </div>
              </div>
              <div class="box">
                <div class="item">
                  <span class="before">{{ $t("meeting.frameRate") }}</span>
                  <my-icon
                    class="iconwangluo_shanghang"
                    :fontSize="16"
                    :iconName="'iconwangluo_shanghang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.shareSentBitrate }}kb/s</span
                  >
                </div>
                <div class="item bottom">
                  <my-icon
                    class="iconwangluo_xiahang"
                    :fontSize="16"
                    :iconName="'iconwangluo_xiahang_24_hei'"
                  ></my-icon>
                  <span class="after"
                    >{{ userStats.shareRecvBitrate }}kb/s</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="btn-area">
            <el-button type="primary" @click="openNetworkWin">{{
              $t("setUp.netDetection")
            }}</el-button>
          </div>
        </div>

        <!-- 反馈 -->
        <div class="setting-feedback" v-show="activeType === 'feedback'">
          <div class="feedback-title">{{ $t("setUp.submitFeedback") }}</div>
          <div class="feedback-contact">
            <span class="feedback-contact-title">{{
              $t("setUp.contactInfo")
            }}</span>
          </div>
          <div class="feedback-button">
            <div class="telephone">{{ $t("setUp.call") }}</div>
            <div class="submit">
              <el-button>{{ $t("setUp.submitFeedback") }}</el-button>
            </div>
          </div>
        </div>

        <!-- 设置截图 -->
        <div
          class="setting-screenshot"
          v-show="activeType === 'screenshot'"
        ></div>
        
        <my-video
          id="settingVideo"
          v-show="['video','beaut','beautBg'].includes(activeType)"
          :width="416"
          :height="234"
          class="video-view"
          :class="activeType == 'video' ? '' :'text-hide'"
        >
        </my-video>

        <!-- 设置视频 -->
        <div class="setting-video" v-show="activeType === 'video'">
          <p class="note">{{ $t("setUp.cameraOK") }}</p>
          <div class="camera">
            <span class="title">{{ $t("setUp.camera") }}</span>
            <el-select
              value-key="deviceId"
              v-model="currentVideoDevice"
              class="userSelect"
              size="mini"
              @change="onCameraSelectChange"
            >
              <el-option
                v-for="item in videoList"
                :key="item.deviceId"
                :label="item.deviceName"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <p class="my-video-title">{{ $t("setUp.myVideo") }}</p>
          <!-- <div class="my-video-checkbox">
              <my-checkbox rowCount="3" @click="onCheckedVideoImage" :parentName="'myVideoImage'" :collection="myVideoImage.collection"
                       class="setting-normal-item"></my-checkbox>
            </div> -->
          <div class="my-video-checkbox">
            <el-checkbox
              :disabled="!deviceResolution720"
              :title="!deviceResolution720 ? '您的电脑硬件暂不支持该功能' : ''"
              v-model:checked="myVideoQuality"
              @change="onCheckedVideoQuality"
              >{{ $t("setUp.highVideo") }}</el-checkbox
            >
          </div>
          <div class="radio-box">
            <el-radio-group
              @change="onCheckedVideoResolution"
              v-model="videoResolution"
              v-show="myVideoQuality"
            >
              <el-radio
                :label="720"
                class="radio-item"
                :title="
                  !deviceResolution1080 ? '您的电脑硬件暂不支持该功能' : ''
                "
                :disabled="!deviceResolution720"
                >{{ "720p" }}</el-radio
              >
              <el-radio
                :label="1080"
                class="radio-item"
                :title="
                  !deviceResolution1080 ? '您的电脑硬件暂不支持该功能' : ''
                "
                :disabled="!deviceResolution1080"
                >{{ "1080p（对网络要求较高）" }}</el-radio
              >
            </el-radio-group>
          </div>

          <div class="my-video-checkbox">
            <el-checkbox
              v-model:checked="myVideoEncoding"
              @change="onCheckedVideoEncoding"
              >启用编码参数</el-checkbox
            >
          </div>
          <div class="radio-box" v-if="myVideoEncoding">
            <div class="argumentsItem">
              <p><span>帧率</span>：</p>
              <el-select
                @change="frameChange('videoLimitValue',currentSelectedVideoFrame)"
                v-model="currentSelectedVideoFrame"
                :disabled="!myVideoEncoding"
                class="userMinSelect setting-frame"
                size="mini"
              >
                  <el-option
                  v-for="item in frameList"
                  :key="item"
                  :label="item"
                  :value="item"
                  >
                </el-option>
              </el-select>
              <p>{{ $t("setUp.second") }}</p>
            </div>
            <div class="argumentsItem">
              <p><span>码率</span>：</p>
              <el-select
                @change="frameChange('videoBitrateValue',currentSelectedVideoBitrate)"
                v-model="currentSelectedVideoBitrate"
                :disabled="!myVideoEncoding"
                class="userMinSelect setting-frame"
                size="mini"
              >

                  <el-option
                  v-for="item in videoBitrates"
                  :key="item"
                  :label="item"
                  :value="item"
                  >
                </el-option>
              </el-select>
              <p>Kbps</p>
            </div>
          </div>

          <div class="code-selection" v-if="browser && !browser.edge">视频编码选择</div>
          <div class="radio-box" v-if="browser && !browser.edge">
            <el-radio-group
              @change="onCheckedVideoCodeModule"
              v-model="videoCodeModule"
            >
              <el-radio :label="0" class="radio-item">{{
                "自动"
              }}</el-radio>

              <el-radio :label="1" class="radio-item">{{
                "AV1（对电脑配置要求较高）"
              }}</el-radio>
              <el-radio :label="2" class="radio-item">{{
                "H.264"
              }}</el-radio>

              <el-radio :label="3" class="radio-item">{{
                "VP9"
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- 虚拟背景 -->
        <div class="setting-video setting-beautBg" v-show="activeType == 'beautBg'">
          <div class="setting-beautBg-desc">
              <span>虚拟背景</span>（支持上传20MB以内的jpg、jpeg、png、bmp格式图片，添加背景比例建议16：9）
          </div>
          <div class="beautBg-container">
            <div class="beautBg-box">
                <!-- 上传组件 -->
                <el-upload
                v-if="localUserId"
                :show-file-list="false"
                :multiplet="false"
                accept=".jpg,.jpeg,.png,.bmp"
                class="upload-demo"
                ref="upload"
                action=""
                :http-request="fileUploadFn"
                :before-upload="beforeUpload"
                >  
                  <div class="upload-submit">
                    <div :class="['text_desc', { 'text_loading_desc': uploading }]"> <span class="span">+</span> {{ uploading ? '上传中...' : '添加背景' }}</div>
                </div>
              </el-upload>
              <!-- 用户上传背景及默认背景效果渲染 -->
            <div 
                v-for="(item, index) in customUploadBgList" 
                :key="index"
                :class="[
                    'user-upload-list',
                    { 'active-arbg-item': activedArBgCore === item.id }
                ]" 
                :style="{
                    backgroundImage: item.src ? `url(${item.src})` : 'none',
                    backgroundPosition: '0 50%',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }"
                @click="hadleSetArBg(item)"
            >
                <div v-if="item.id ==='transparent'">无</div>
                <div v-if="item.id === 'blur'" style="color: #FFF; letter-spacing: 2px;">模糊效果</div>
                <img
                    v-if="item.id !=='transparent' && item.id !=='blur'"
                    class="upload-bg-delete"
                    @click.stop="hadleRemoteBtn(item.id)"
                    src="@/assets/images/beaut/icon-del-white.png" 
                    alt="删除" 
                />
            </div>

            </div>
            <div class="beautBg-h2">精选背景</div>
            <div class="beautBg-box">
              <div 
                  v-for="(item, index) in systemArBgList" 
                  :key="index"
                  :class="[
                      'user-upload-list',
                      { 'active-arbg-item': activedArBgCore === item.id }
                  ]"
                  :style="{
                      backgroundImage: item.src ? `url(${item.src})` : 'none',
                      backgroundPosition: '0 50%',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                  }"
                  @click="hadleSetArBg(item)"
              />
            </div>
          </div>
        </div>
        <!-- 设置美颜 -->
        <div
          class="setting-video setting-beaut"
          v-show="activeType === 'beaut'"
        >
        
          <div class="switch-warp">
            <el-switch
              v-model="openVideoBeaut"
              class="switch"
              active-text="美颜"
              inactive-text="美颜"
              @change="onOffChange"
            />
          </div>

          <div class="control-bar"  v-show="videoBeautLeave === 4 && activeBeaut !== 0 && openVideoBeaut">
            <el-slider
              class="slider"
              @change="beautDiyBarChange"
              input-size="mini"
              :min="0"
              :max="10"
              v-model="beautDiyDate[activeBeaut].value"
              :show-tooltip="false"
            ></el-slider>
            <div class="control-bar-number">{{ beautDiyDate[activeBeaut].value}}</div>
          </div>
          <div class="beaut-control-box">
            <el-radio-group
            v-if="openVideoBeaut"
             class="beaut-radio-warp"
              @change="onCheckedBeautLeave"
              v-model="videoBeautLeave"
            >
              <el-radio :label="1" class="radio-item">轻度美颜</el-radio>
              <el-radio :label="2" class="radio-item">中度美颜</el-radio>
              <el-radio :label="3" class="radio-item">重度美颜</el-radio>
              <el-radio :label="4" class="radio-item">自定义</el-radio>
            </el-radio-group>
          </div>
          <ul class="beaue-diy-warp"  v-show="openVideoBeaut && videoBeautLeave === 4">
            <li @click="beautTypeChange(index)" v-for="(item,index) in beautTypeArr" :key="index">
            <img
              :src="require(`@/assets/images/beaut/beaut${index+1}-${getBeautImgIndex(index)}.png`)"
              alt/>{{item}}</li>
          </ul>

          <!-- 重置 自定义 效果 -->
          <div class="resetConfirmMask" v-if="showBeautReset"></div>
          <div
            class="resetConfirm"
            width="380px"
            v-if="showBeautReset"
          >
            <div class="content">
              {{$t('确认全部恢复默认效果吗?')}}
            </div>

            <span class="dialog-footer">
              <el-button @click="showBeautReset = false">{{$t('取消')}}</el-button>
              <el-button type="primary" @click="beautReset">{{$t('确认')}}</el-button>
            </span>
          </div>
        </div>

        <!-- 设置音频 -->
        <div class="setting-audio" v-show="activeType === 'audio'">
          <div class="audio">
            <div class="micro">
              <span class="title">{{ $t("setUp.microphone") }}</span>

              <el-select
                @change="audioChange"
                value-key="deviceId"
                v-model="currentMicDevice"
                class="userSelect"
                size="mini"
              >
                <el-option
                  v-for="item in audioList"
                  :key="item.deviceId"
                  :label="item.deviceName"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="audio-volume">
              <span class="title">{{ $t("setUp.voice") }}</span>

              <div class="slider-box">
                <el-slider
                  class="slider"
                  @change="audioVolumeChange"
                  input-size="mini"
                  :min="0"
                  :max="100"
                  v-model="audioVolumeValue"
                ></el-slider>

                <div class="slider-text">{{ audioVolumeValue }}</div>
              </div>
            </div>
            <div class="audio-status">
              <div class="box">
                <div class="status-show">
                  <progressBar :percent="currentHuaTongPercent"></progressBar>
                </div>
                <div class="status-tips">{{ $t("setUp.microphoneOk") }}</div>
              </div>
            </div>
          </div>
          <div class="audio">
            <div class="micro">
              <span class="title">{{ $t("setUp.speaker") }}</span>
              <div class="micro-right">
                <el-select
                  @change="speakChange"
                  value-key="deviceId"
                  v-model="currentSpeakerDevice"
                  class="userSelect"
                  size="mini"
                >
                  <el-option
                    v-for="item in speakerList"
                    :key="item.deviceId"
                    :label="item.deviceName"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <el-button
                  class="speaker-button"
                  type="primary"
                  size="mini"
                  :title="
                    beginTestSpeaker
                      ? $t('setUp.stopVoice')
                      : $t('setUp.addSpeack')
                  "
                  @click="onTestMySpeaker"
                  >{{
                    beginTestSpeaker
                      ? $t("setUp.stopDetection")
                      : $t("setUp.testLoudspeaker")
                  }}</el-button
                >
              </div>
            </div>
            <div class="audio-volume">
              <span class="title">{{ $t("setUp.voice") }}</span>

              <div class="slider-box">
                <el-slider
                  class="slider"
                  @change="speakerVolumeChange"
                  input-size="mini"
                  :min="0"
                  :max="100"
                  v-model="speakerVolumeValue"
                ></el-slider>

                <div class="slider-text">{{ speakerVolumeValue }}</div>
              </div>
            </div>
            <div class="audio-status">
              <div class="box">
                <div class="status-tips">
                  {{ beginTestSpeaker ? $t("setUp.voiceOk") : "" }}
                </div>
              </div>
            </div>
            <!-- 音频 测试扬声器 -->
            <audio
              ref="playoutAudio"
              class="testPlayoutVolume media-reset-flag"
              loop
              src="~@/assets/speaker_test_sound.wav"
            ></audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocalDeviceInfo, setLocalDeviceInfo } from "@/utils/device";
import { ping } from "@/utils/network";
import { signalUrl } from "@/config/index";
import { NETWORK_TEST_URL, MAX_SHARE_VIDEO_FPS } from "@/constant/index";
import Lang from "@/components/lang/index";
import progressBar from "@/components/progressBar/index";
import myVideo from "@/components/video/index";
import { loganLog } from "@/utils/log";
import { strToBoolean  } from '@/utils/str'
import debounce from 'lodash.debounce'
import { THROTLE_TIME } from "@/constant/index";
import { getBrowser } from "@/utils/tool"
import {  checkDevicePermission } from "@/utils/device"
import { getTentantArVirList,qureyImgUpload,qureyImgDelete } from '@/api/meet'
import {  getlocalUserId } from '@/utils/auth'

export default {
  name: "Setting",
  components: {
    Lang,
    progressBar,
    myVideo,
  },
  data() {
    return {
      Resolution: ["1280x720","1920x1080","2560x1440"], //分辨率
      frameList: [10, 15, 25, 30, 60], //帧/秒
      shareBitrates: [1500,2000,2500,3000,4000,5000,6000,8000,10000], //共享码率
      videoBitrates: [200,300,400,500,550,600,700,800,1000], //人像码率
      shareBitrate1: [500,600,700,800,900,1000,1200,1500,2000], //码率1
      shareBitrate2: [1000,1500,2000,2500,3000,4000,5000,6000,8000], //码率2
      shareBitrate3: [1500,2000,2500,3000,4000,5000,6000,8000,10000], //码率3
      videoBitrate1: [200,300,400,500,550,600,700,800,1000], //码率1
      videoBitrate2: [500,600,700,800,900,1000,1200,1500,2000], //码率2
      videoBitrate3: [1000,1500,2000,2500,3000,4000,5000,6000,8000], //码率3

      currentSelectedResolution : "2560x1440",
      currentSelectedShareFrame : MAX_SHARE_VIDEO_FPS,
      currentSelectedVideoFrame: 15,
      currentSelectedShareBitrate:3000,
      currentSelectedVideoBitrate:550,
      activeIndex: 0,
      activeType: "normal",
      preActiveType: "normal",

      hideNoVideoMember: false,
      showDuration: false,
      frames: false,
      myVideoQuality: false,
      myVideoEncoding: false,

      navLock:false,//tab按钮锁，因为stop美颜有异步的返回

      videoResolution: 720, // 0 720p 、 1 1080p
      videoCodeModule: 2, // 0 默认H.264、1 av1
      showBeautReset:false,//重置美颜效果弹窗
      openVideoBeaut:false,//是否开启美颜
      videoBeautLeave: 1, //美颜级别默认1
      activeBeaut:1,//美颜类型,
      deviceResolution720: false,
      deviceResolution1080: false,
      beautTypeArr:['恢复','美白','磨皮','瘦脸','削脸','大眼','下巴'],
      isBeautDiy:0,
      beautDiyDate:[
        {name:'reset'},
        {name:'whitenessLevel',value:0,fun:'setWhitenessLevel'},//美白
        {name:'dermabrasionLeve',value:0,fun:'setDermabrasionLevel'},//磨皮
        {name:'faceSlimLeve',value:0,fun:'setFaceSlimLevel'}, //瘦脸
        {name:'faceShaveLevel',value:0,fun:'setFaceShaveLevel'},//削脸
        {name:'eyeScaleLevel',value:0,fun:'setEyeScaleLevel'},//大眼
        {name:'chinLevel',value:0,fun:'setChinLevel'},//下巴
      ],

      // myVideoImage: {checked: [], collection: [{id: 1, name: this.$t('setUp.useVideo')}]},
      // myVideoSpeed: {checked: [], collection: [{id: 1, name: this.$t('setUp.useHardware')}]},

      currentHuaTongPercent: 0,
      currentYangShengQiPercent: 0,
      beginTestSpeaker: false, //是否开始测试扬声器

      networkProgressValue: 3,
      currentNetworkProgressValue: 0,
      currentNetworkProgressTip: "",
      showNetworkResult: false,
      networkLoading: false,
      localNetworkTime: 0,
      serverNetworkTime: 0,
      statusIconClassName: "",
      statusTitle: "",
      micTestVolumeInterval: null,

      userAudioStats: [], // 音频信息
      userVideoStats: [], // 视频信息
      userShareStats: [], // 共享信息

      userStats: {
        //汇总
        rtt: 0, //网络延迟
        sentBitrate: 0, //带宽上行
        recvBitrate: 0, //带宽下行
        sentLossRate: 0, //上行丢包率
        recvLossRate: 0, //下行丢包率
        networkType: "", //网络类型：wifi 有线

        audioSentBitrate: 0, //音频上行码率
        audioRecvBitrate: 0, //音频下行码率
        micDb: 0, //麦克风DB
        speakerDb: 0, //扬声器DB

        videoSentHeight: 0, //视频上行分辨率
        videoSentWidth: 0,
        videoRecvHeight: 0, //视频下行分辨率
        videoRecvWidth: 0,
        videoSentFps: 0, //视频上行帧率
        videoRecvFps: 0, //视频下行帧率
        videoSentBitrate: 0, //视频上行码率
        videoRecvBitrate: 0, //视频下行码率

        shareSentHeight: 0, //屏幕共享上行分辨率
        shareSentWidth: 0,
        shareRecvHeight: 0, //屏幕共享下行分辨率
        shareRecvWidth: 0,
        shareSentFps: 0, //屏幕共享下行帧率
        shareRecvFps: 0, //屏幕共享下行帧率
        shareSentBitrate: 0, //屏幕共享下行码率
        shareRecvBitrate: 0, //屏幕共享下行码率
      },

      audioVolumeValue: 0,
      speakerVolumeValue: 0,
      currentMicDevice: null,
      currentSpeakerDevice: null,
      currentVideoDevice: null,
      cameraDeviceHighDefinition: {}, // 各摄像头设备高清画质选中状态 {  [key: cameraDeviceId]:  this.myVideoQuality }

      browser:null, //ua类型

      uploading: false,
      activedArBgCore: 'transparent',
      customUploadBgList: [
          {
              id: 'transparent',
          },
          {
              id: 'blur',
              src: "https://100live-82-1259387452.cos.ap-beijing.myqcloud.com/100Live-web-arbg/arBlureffect_1186683154866511872.png",
          },
      ],
      systemArBgList: [],
      localUserId:''
    };
  },
  props: {
    activeDefaultIndex: { default: 0 },
    isShow: { default: true },
    isMeeting: { default: false },
    myCamera: { default: false },
  },
  watch: {
    activeDefaultIndex(value) {
      this.activeIndex = value;
      const navType = this.navList[value].type;

      this.activeType = navType;
      
      if (this.activeType === "network") {
        this.startNetworkDetection();
      }
    },
    isShow(value) {
      if (value) {
        this.initSetting(this.activeType); //activeType watch的时候也会initSetting 所以init only要加防抖
      } else { //放到关闭按钮上
       
      }
    },
    async activeType(value,oldValue) {
      if (value === "audio") {
        if (!this.$deviceControl) return;
        checkDevicePermission('audio')
        .catch(() => {
          this.$totast(`系统检测未授权浏览器麦克风权限`)
        })
      }
      if (value === "beaut" || value === "video" || value === "beautBg") {
        if (!this.$deviceControl) return;
        checkDevicePermission('video')
        .catch(() => {
          this.$totast(`系统检测未授权浏览器摄像头权限`)
        })
      }

      await this.initSetting(value);
      
      if (value !== "video"  && value !== "beaut"  && value !== "beautBg" && this.$deviceControl && (oldValue === "video" || oldValue === "beaut" || oldValue === "beautBg")) {
        this.stopLocalPreviewOnly(null,`activeType not video --${value}`)
      }

      if(value === 'video' && oldValue === 'normal') {
        if(this.currentVideoDevice && this.currentVideoDevice?.deviceId){
          this.cameraDeviceHighDefinition[this.currentVideoDevice.deviceId] = this.myVideoQuality
        }
      }
    },
    currentNetworkProgressValue(value) {
      if (value <= this.networkProgressValue && value > 0) {
        const tips = [
          this.$t("setUp.netConnection"),
          this.$t("setUp.serverConnection"),
          this.$t("setUp.connectionTime"),
        ];
        this.currentNetworkProgressTip =
          this.$t("setUp.detecting") + `${tips[value - 1]}`;
      }
      if (value === this.networkProgressValue) {
        this.showNetworkResult = true;
        this.networkLoading = false;
      }
    },
    serverNetworkTime(value) {
      if (value < 0 || this.localNetworkTime <= 0) {
        //-1 超时
        this.statusIconClassName = "iconwangluojiance_wuwang_48_hong";
        this.statusTitle = this.$t("setUp.abnormal");
      } else if (value > 0 && value < 100) {
        //正常 良好
        this.statusIconClassName = "iconwangluojiance_wancheng_48_lv";
        this.statusTitle = this.$t("setUp.ok");
      } else if (value >= 100 && value < 300) {
        //较差
        this.statusIconClassName = "iconwangluojiance_jiaocha_48_cheng";
        this.statusTitle = this.$t("setUp.poor");
      } else if (value >= 300) {
        //极差
        this.statusIconClassName = "iconwangluojiance_jiaocha_48_cheng";
        this.statusTitle = this.$t("setUp.range");
      }
    },

    audioVolumeValue(value) {
      this.$store.commit("device/setAudioVolumeValue", value);
    },
    audioVolumeState: {
      immediate: true,
      handler(value) {
        this.audioVolumeValue = value;
      },
    },
    myVideoQuality(newvlu){

    },

    currentMicDevice(value) {
      this.$store.commit("device/setCurrentMicDevice", value);
    },
    audioDeviceState: {
      immediate: true,
      handler(value) {
        this.currentMicDevice = value;
      },
    },

    speakerVolumeValue(value) {
      this.$store.commit("device/setSpeakerVolumeValue", value);
    },
    speakerVolumeState: {
      immediate: true,
      handler(value) {
        this.speakerVolumeValue = value;
      },
    },

    currentSpeakerDevice(value) {
      this.$store.commit("device/setCurrentSpeakerDevice", value);
    },
    speakerDeviceState: {
      immediate: true,
      handler(value) {
        this.currentSpeakerDevice = value;
      },
    },
    beautDiyDate: {
      immediate: true,
      deep: true,
      handler() {
        //滑动实时生效
        if (this.$store.state.isShowSettingPanel || this.activeIndex === 3) {
          this.beautDiyBarChange()
        }
      },
    },

    async currentVideoDevice(value, oldValue) {
      // 设备发生变化要再取分辨率
      if (
        this.isShow &&
        value &&
        value.deviceId &&
        (!oldValue || value.deviceId !== oldValue.deviceId)
      ) {
        this.$store.commit("device/setCurrentCameraDevice", value);

        this.initVideoResolution(value.deviceId)
        //设备发生变化以后更新摄像头设备
        if (oldValue) {
          loganLog(`摄像头ID发生变化-切换摄像头-value:${value.deviceId}`)
          try {
            await this.stopLocalPreviewOnly(null,'set new Video')
          } catch (error) {
            loganLog(`set new Video--- error:${JSON.stringify(error)}`)
          }
          try {
            await this.$deviceControl.setCurrentCameraDevice(value.deviceId, this.isMeeting);
          } catch (error) {
            loganLog(`setCurrentCameraDevice--- error:${JSON.stringify(error)}`)
          }

          await this.startSettingVideoView(value.deviceId);
        }
      }
    },

    videoDeviceState: {
      immediate: true,
      handler(value) {
        this.currentVideoDevice = value;
      },
    },
  },
  computed: {
    selfInfo() {
      return this.$store.getters["member/getUser"](this.$configs.peerId);
    },
    getControlBarNum() {
      return 30;
    },
    isUseYangShengQi() {
      return this.$store.state.meet.isUseYangShengQi;
    },
    isUseHuaTong() {
      return this.$store.state.meet.isUseHuaTong;
    },
    audioList() {
      return this.$store.state.device.micDeviceList;
    },
    audioVolumeState() {
      return this.$store.state.device.audioVolumeValue;
    },
    audioDeviceState() {
      return this.$store.state.device.currentMicDevice;
    },
    speakerList() {
      return this.$store.state.device.speakerDeviceList;
    },
    speakerVolumeState() {
      return this.$store.state.device.speakerVolumeValue;
    },
    speakerDeviceState() {
      return this.$store.state.device.currentSpeakerDevice;
    },
    videoList() {
      return this.$store.state.device.cameraDeviceList;
    },
    videoDeviceState() {
      return this.$store.state.device.currentCameraDevice;
    },
    navList() {
      return [
        {
          id: 1,
          type: "normal",
          name: this.$t("setUp.normal"),
        },
        {
          id: 2,
          type: "audio",
          name: this.$t("setUp.audio"),
        },
        {
          id: 3,
          type: "video",
          name: this.$t("setUp.video"),
        },
        // {
        //   id: 4,
        //   type: 'record',
        //   name: '录制'
        // },
        // {
        //   id: 5,
        //   type: 'screenshot',
        //   name: '截图'
        // },
        {
          id: 10,
          type: "beautBg",
          name: this.$t("虚拟背景"),
        },
        {
          id: 9,
          type: "beaut",
          name: this.$t("美颜"),
        },
        {
          id: 6,
          type: "network",
          name: this.$t("setUp.netDetection"),
        },
        // {
        //   id: 7,
        //   type: 'feedback',
        //   name: '反馈'
        // },
        // {
        //   id: 8,
        //   type: 'quality',
        //   name: '质量检测'
        // },
      ];
    },
    _gpuLevel() {
      return this.$store.state._gpuLevel
    }
  },
  beforeDestroy() { //注释掉
    // this.destroyMicroTest();
    // if (this.$deviceControl) {
    //   this.stopLocalPreviewOnly(null,'beforeDestroy')
    // }
  },
  methods: {
    debounceHandle: debounce(fn => fn && fn(), 300, { leading: true, trailing: false }),

    async initVideoResolution(deviceId, callback = null){
        let deviceResolution = await this.getLocalResolution(deviceId);

        const localDeviceInfo = getLocalDeviceInfo(deviceId);
        if (localDeviceInfo) {
          console.log('getRemoteMediaStats:', localDeviceInfo)

        }

        this.deviceResolution720 = false;
        this.deviceResolution1080 = false;
        // const aaa = this.$roomClient.getRemoteMediaStats()
        deviceResolution.forEach((element) => {
          if (element === 720) {
            this.deviceResolution720 = true;
          } else if (element === 1080) {
            this.deviceResolution1080 = true;
          }
        });

        if (!this.deviceResolution1080) {
          this.videoResolution = 720;
          // setLocalDeviceInfo({
          //   videoResolution: this.myVideoQuality ? 720 : 0,
          // });
        }

        console.error("getRemoteMediaStats:", deviceResolution);
        if (!this.deviceResolution720) {
          this.videoResolution = 0;
          this.myVideoQuality = false;

          if(this.currentVideoDevice && this.currentVideoDevice?.deviceId){
            this.cameraDeviceHighDefinition[this.currentVideoDevice.deviceId] = false
          }

          setLocalDeviceInfo({
            videoQuality: 0,
            videoResolution: 0,
          });
        } else {
          this.videoResolution = 720;

          for (const key in this.cameraDeviceHighDefinition) {
            if (Object.hasOwnProperty.call(this.cameraDeviceHighDefinition, key)) {
              const element = this.cameraDeviceHighDefinition[key];
              if(element){
                this.cameraDeviceHighDefinition[this.currentVideoDevice.deviceId] = true;
                this.myVideoQuality = true;
                break;
              }
            }
          }
          if(localDeviceInfo.videoResolution > 720) {
            this.videoResolution = localDeviceInfo.videoResolution;
            // 处理- 是否需要调整到 720P
            let deviceResolution = await this.getLocalResolution(deviceId);
            console.log('[debug info] 处理当前resolution:', deviceResolution)
            if(deviceResolution[0] == 720) {
              this.videoResolution = 720;
            } else if(deviceResolution[0] ==1080) {
              this.videoResolution = 1080;
            }
          } else {
            this.videoResolution = 720
          }

          setLocalDeviceInfo({
            videoQuality: this.myVideoQuality ? 1 : 0,
            videoResolution: this.videoResolution,
          });
        }

        this.videoBitrates = this.myVideoQuality && this.videoResolution == 1080 ? this.videoBitrate3 :  this.myVideoQuality && this.videoResolution == 720 ? this.videoBitrate2 : this.videoBitrate1
        callback && callback()
    },
    speakChange(e) {
      this.$deviceControl.setCurrentSpeakerDevice(e.deviceId);
    },
    //获取自定义美颜效果图片
    getBeautImgIndex(index){
      let _i = this.activeBeaut === index ?  this.beautDiyDate[index].value < 1 ? 4 : 3 : this.beautDiyDate[index].value < 1 ? 2 : 1
      if(index == 0){
        _i = this.isBeautDiy ?  this.activeBeaut === index ? 4 : 2 :1
      }
      return _i
    },
    //美颜开关
    onOffChange(){
        // if (this.browser.chrome && this.browser.isMac || (this.browser.safari && this.browser.safari >= 15)){
        if (!(this.browser.safari && this.browser.safari < 15)){
          if(this.openVideoBeaut){
          this.onCheckedBeautLeave()
        }else{
          this.$deviceControl.setBeautyLevel(0)
        }
        this.$saveLocal("isBeauty", this.openVideoBeaut?"true":"false");
        this.$emit("isBeautyChange",this.openVideoBeaut?"true":"false");
      }else{
        this.openVideoBeaut = false
        if(this.browser.safari && this.browser.isMac){
          this.$totast('浏览器版本暂不支持此功能，请升级至15.0版本以上使用')
        }else{
          this.$totast('美颜功能开发中，敬请期待')
        }
        loganLog(`初始化不支持美颜--safari:${this.browser.safari}-chrome:${ this.browser.chrome}-isMac:${ this.browser.isMac}`)
      }
    },
    //自定义美颜效果切换
    beautTypeChange(i){
      if(i == 0 && this.isBeautDiy){
        this.showBeautReset = true
      }
      this.activeBeaut = i
    },
    //重置美颜效果
    beautReset(){
      this.showBeautReset = false
      this.foreachRunBeaut(true)
      this.isBeautDiy = false
      this.activeBeaut = 1
    },
    //关闭预览
    stopLocalPreviewOnly(){
      this.debounceHandle(async ()=>{
        this.navLock = true
        try {
          if(!this.myCamera){
            const videoEl = document.getElementById('settingVideo');
            videoEl && (videoEl.srcObject = null);
            await this.$deviceControl.stopLocalPreviewOnly(...arguments);
          }
          this.navLock = false
        } catch (error) {
          this.navLock = false
        }
      })
    },
    async getLocalResolution(deviceId) {
      let deviceResolution = getLocalDeviceInfo()["resolution" + deviceId] || "";

      if (deviceResolution) {
        deviceResolution = JSON.parse(deviceResolution);
        if(!deviceResolution.includes(720)){
          loganLog('获取分辨率缓存中没有720重新找sdk获取')
          deviceResolution = await this.$deviceControl.getDeviceResolution(
            deviceId
          );
          console.error("获取分辨率", deviceId,deviceResolution);
          setLocalDeviceInfo({
            ["resolution" + deviceId]: JSON.stringify(deviceResolution),
          });
        }
      } else {
        deviceResolution = await this.$deviceControl.getDeviceResolution(
          deviceId
        );
        console.error("获取分辨率", deviceId,deviceResolution);
        setLocalDeviceInfo({
          ["resolution" + deviceId]: JSON.stringify(deviceResolution),
        });
      }
      return deviceResolution;
    },
    speakerVolumeChange(e) {
      // 将音量值存储到本地
      setLocalDeviceInfo({
        speakerValue: this.speakerVolumeValue,
      });

      if (Number.isInteger(this.speakerVolumeValue)) {
        this.$refs["playoutAudio"].volume = 0.01 * this.speakerVolumeValue;
      }

      //会中设置逻辑 音量即时生效
      if (this.isMeeting && this.isUseYangShengQi) {
        const audioEl = document.querySelectorAll(`.audioPlayoutVolumeItem`);
        this.$meetingControl.setAudioPlayoutVolume(
          this.speakerVolumeValue,
          audioEl
        );
        loganLog(`面板设置扬声器音量：${this.speakerVolumeValue}`);
      }
    },
    audioChange(e) {
      this.$deviceControl.setCurrentMicDevice(e.deviceId, this.isMeeting);
    },
    audioVolumeChange(e) {
      // 将音量值存储到本地
      setLocalDeviceInfo({
        audioValue: this.audioVolumeValue,
      });

      //会中设置逻辑 音量即时生效
      if (this.isMeeting && this.isUseHuaTong) {
        this.$roomClient.setAudioCaptureVolume(this.audioVolumeValue);
        const selfId = this.$configs.peerId;
        loganLog(`面板设置麦克风音量：${this.audioVolumeValue}`);

        //  if(this.audioVolumeValue === 0 && selfId) { //音量为0 关闭麦克风
        //     // update vuex isUseHuaTong
        //     this.$store.commit('member/updateUser', {
        //       userId: selfId,
        //       isUseHuaTong: false
        //     })
        //  }
      }
    },
    beautDiyBarChange() {
      if(this.videoBeautLeave == 4 && this.openVideoBeaut){
        let _beautDate = this.beautDiyDate[this.activeBeaut]
        //执行具体美颜效果
        this.$deviceControl.setBeauty(_beautDate.fun,_beautDate.value)
        //判断div的值全部小于0的时候 置灰 恢复按钮
        if(_beautDate.value > 0){
          this.isBeautDiy = 1
        }else{
          this.isBeautDiy = 0
          this.beautDiyDate.forEach((element,i) => {
            if(i < 1) return
            if(element.value > 0) this.isBeautDiy = 1
          });
        }
        setLocalDeviceInfo({
          [_beautDate.name] : _beautDate.value,
        });
      }
    },
    foreachRunBeaut(isReset){
      this.isBeautDiy = 0
      this.beautDiyDate.forEach((element,i) => {
        if(i < 1) return
        if(element.value > 0) this.isBeautDiy = 1

        if(isReset){
          if(element.value > 0){
            element.value = 0
            this.$deviceControl.setBeauty(element.fun,0)
            setLocalDeviceInfo({
              [element.name] :0
            });
          }
        }else{
          element.value > 0 && this.$deviceControl.setBeauty(element.fun,element.value)
        }
      });
    },
    frameChange(name,value) {
      console.error('设置参数',name,value)
      setLocalDeviceInfo({
        [name]: value,
      });
    },
    resolutionChange(e) {
      this.currentSelectedShareFrame = MAX_SHARE_VIDEO_FPS
      setLocalDeviceInfo({
        shareLimitValue: MAX_SHARE_VIDEO_FPS,
      });
      if(e == '1280x720'){
        this.shareBitrates =  this.shareBitrate1
        this.currentSelectedShareBitrate = 1200

        setLocalDeviceInfo({
          shareBitrateValue: 1200,
          shareResolutiontValue: "1280x720"
        });
      }
      if(e == '1920x1080'){
        this.shareBitrates =  this.shareBitrate2
        this.currentSelectedShareBitrate = 2000
        setLocalDeviceInfo({
          shareBitrateValue: 2000,
          shareResolutiontValue: "1920x1080"
        });
      }
      if(e == '2560x1440'){
        this.shareBitrates =  this.shareBitrate3
        this.currentSelectedShareBitrate = 3000
        setLocalDeviceInfo({
          shareBitrateValue: 3000, 
          shareResolutiontValue: "2560x1440"
        });
      }
    },
    bitrateChange(e) {
      setLocalDeviceInfo({
        shareBitrateValue: e,
      });
    },
    initSetting: debounce(function(value) {
      //初始化常规数据
      if (value === "normal") {
        this.loadNormalSetting();
      }
      //初始化视频数据
      if (value === "video") {
        this.loadVideoSettng();
      }
      //初始化美颜数据
      if (value === "beaut") {
        this.loadBeautSettng();
      }
      //初始化虚拟背景
      if (value === "beautBg") {
        this.loadBeautBgSettng();
      }
      //初始化音频数据
      if (value === "audio") {
        this.loadAudioSettng();
      } else if(this.preActiveType == 'audio') {
        this.destroyMicroTest();
      }

      //初始化质量检测
      if (value === "quality") {
        //this.getStats()
      }
    }, 50),
    handleNav(item, index) {
      if(!this.navLock){ // 如果上锁了不能点击
        if(index == 3 && this._gpuLevel == 'low'){
          this.$totast('设备性能不支持虚拟背景功能')
          return
        }
        if(index == 4 &&  ['low','middle'].includes(this._gpuLevel)){
          this.$totast('设备性能不支持美颜功能')
          return
        }
        this.activeIndex = index;
        this.preActiveType = this.activeType
        this.activeType = item.type;
      }else{
        loganLog('navLock锁定了不能点击')
      }
    },
    close() {
      if (this.activeIndex === 2 || this.activeIndex === 3 || this.activeIndex === 4) {
        this.stopLocalPreviewOnly(null,'btn close')
      }
      //停用麦克风测试
      if(this.activeType == 'audio'){
        this.destroyMicroTest();
      }
      this.navLock = false
      this.preActiveType = "normal"

      this.$emit("close");
    },
    //常规设置
    loadNormalSetting() {
      const localDeviceData = getLocalDeviceInfo();

      //隐藏非视频参会者 选项
      const defaultHideVal = localDeviceData.hideNoVideoMember
      this.hideNoVideoMember = defaultHideVal ? true : false

      this.$store.commit('member/updateHideNoVideoMember', defaultHideVal ? 1 : 0)

      //显示我的参会时长 首次默认选中
      const defaultMark = localDeviceData.joinDurationTag;
      if (!defaultMark && !localDeviceData.joinDuration) {
        this.showDuration = true;
        setLocalDeviceInfo({
          joinDurationTag: 1,
          joinDuration: 1,
        });
        this.$store.commit("meet/updateGlobalMeetState", {
          joinDuration: 1,
        });
      } else if (localDeviceData.joinDuration > 0) {
        this.showDuration = true;
      }
      //我的屏幕共享限制 以及时长 首次默认选中
      const defaultShareMark = localDeviceData.shareLimitTag;

      if (!defaultShareMark && !localDeviceData.shareLimit) {
        this.frames = true;
        setLocalDeviceInfo({
          shareLimitTag: 1,
          shareLimit: 1,
        });
      } else if (localDeviceData.shareLimit > 0) {
        this.frames = true;
      }
    },
    onCheckedNormalSetting(type) {
      if (type === 2) { //隐藏视频参会者 选项
        setLocalDeviceInfo({
          hideNoVideoMember: this.hideNoVideoMember ? 1 : 0
        })
        this.$store.commit('member/updateHideNoVideoMember', this.hideNoVideoMember ? 1 : 0)

        //每次切换该选项时，需初始一下分页参数
        this.$store.commit("member/updatePageIndex", 1)
      }
      if (type === 3) {
        setLocalDeviceInfo({
          joinDurationTag: 1,
          joinDuration: this.showDuration ? 1 : 0,
        });
        this.$store.commit("meet/updateGlobalMeetState", {
          joinDuration: this.showDuration ? 1 : 0,
        });
      }
      if (type === 4) {
        setLocalDeviceInfo({
          shareLimitTag: 1,
          shareLimit: this.frames ? 1 : 0,
        });
      }
    },

    //视频设置
    async loadVideoSettng() {
      if (
        this.currentVideoDevice &&
        this.currentVideoDevice.deviceId &&
        this.isShow &&
        this.activeType === "video"
      ) {

        if(this.preActiveType != 'beaut' && this.preActiveType != 'beautBg'){
          await this.startSettingVideoView(this.currentVideoDevice.deviceId);
        }
        //画质
        const localDeviceData = getLocalDeviceInfo();
        this.myVideoQuality = localDeviceData.videoQuality ? true : false;

        this.initVideoResolution(this.currentVideoDevice.deviceId, ()=> {});

        //分辨率
        this.videoResolution = localDeviceData.videoResolution
          ? localDeviceData.videoResolution
          : 720;
        this.videoCodeModule = localDeviceData.videoCodeModule
          ? localDeviceData.videoCodeModule
          : 2;
      }
    },
    //美颜背景
    async loadBeautBgSettng(){
      if (this.isShow && this.activeType === "beautBg") {
        let video = document.getElementById('settingVideo');
        if(this.preActiveType != 'video' && this.preActiveType != 'beaut'){
          await this.$deviceControl.startLocalPreviewOnly(this.currentVideoDevice.deviceId,video)
        }
      }
    },
    //美颜设置
    async loadBeautSettng() {
      if (this.isShow && this.activeType === "beaut") {
        let video = document.getElementById('settingVideo');
        // console.error(video)
        // console.error(this.currentVideoDevice.deviceId)
        // 设置预览
        if(this.preActiveType != 'video' && this.preActiveType != 'beautBg'){
          await this.$deviceControl.startLocalPreviewOnly(this.currentVideoDevice.deviceId,video)
        }
        let isBeauty = this.$loadLocal("isBeauty")
        this.openVideoBeaut = strToBoolean(isBeauty, false)//是否开启美颜

        const localDeviceData = getLocalDeviceInfo();
        const videoBeautLeave = localDeviceData.videoBeautLeave; //美颜等级
        const whitenessLevel = localDeviceData.whitenessLevel; //美白等级
        const dermabrasionLeve = localDeviceData.dermabrasionLeve; //磨皮等级
        const faceSlimLeve = localDeviceData.faceSlimLeve; //瘦脸等级
        const faceShaveLevel = localDeviceData.faceShaveLevel; //削脸等级
        const eyeScaleLevel = localDeviceData.eyeScaleLevel; //大眼等级
        const chinLevel = localDeviceData.chinLevel; //下巴等级

        this.videoBeautLeave = videoBeautLeave ? videoBeautLeave : 1;
        if (whitenessLevel) this.beautDiyDate[1].value = whitenessLevel
        if (dermabrasionLeve) this.beautDiyDate[2].value = dermabrasionLeve
        if (faceSlimLeve) this.beautDiyDate[3].value = faceSlimLeve
        if (faceShaveLevel) this.beautDiyDate[4].value = faceShaveLevel
        if (eyeScaleLevel) this.beautDiyDate[5].value = eyeScaleLevel
        if (chinLevel) this.beautDiyDate[6].value = chinLevel
        if(this.openVideoBeaut){
          this.onCheckedBeautLeave()
        }else{
          this.$deviceControl.setBeautyLevel(0)
        }
      }
    },
    //重置美颜画面
    async refreshLocalPreviewOnly(){
      let video = document.getElementById('settingVideo');
      await this.$deviceControl.stopLocalPreviewOnly(video,'refreshLocalPreviewOnly');
      try {
        await this.$deviceControl.startLocalPreviewOnly(this.currentVideoDevice.deviceId,video)
      } catch (error) {
       console.error('startLocalPreviewOnly error',error)
      }
    },
    async startSettingVideoView(selectValue) {
      if (this.isShow) {
        try {
          let video = document.getElementById('settingVideo');
          await this.$deviceControl.startLocalPreviewOnly(selectValue,video)
        } catch (error) {
         console.error('startSettingVideoView error',error)
        }
      }
    },
    // 设置Ar虚拟背景
    async hadleSetArBg (bgItem) {
      let type = bgItem.id !== 'transparent' && bgItem.id !== 'blur' ? 'image' : bgItem.id;
      
      try {
          console.error('设置背景',bgItem)
          await this.$deviceControl.setVirtuallyBackground({
              type: type,
              src: type === 'image' ? bgItem.src : undefined,
          });
          
          setLocalDeviceInfo({
            arBgId: bgItem.id,
          });

          this.activedArBgCore =  bgItem.id

          // 更新本地缓存背景选中信息
          // updateArActiveCore(this.activedArBgCore);
      } catch (error) {
          console.error('[SetArBg.vue]设置虚拟背景失败Error:', error);
      }
    },
    hadleRemoteBtn(id){
      this.$confirm('确定要删除自定义背景吗？','',{
        confirmButtonText:'删除',
        center: true
      }).then(async () => {
        try {
          await qureyImgDelete(id)
          if(this.activedArBgCore == id){
            this.activedArBgCore = 'transparent'
            this.hadleSetArBg({
              id: 'transparent'
            })
          }
          this.customUploadBgList = this.customUploadBgList.filter(item => id !== item.id)
        } catch (error) {
          loganLog('删除图片 error',error)
        }

      })
    },
    async fileUploadFn (options) {
      let { file} = options;
      const formData = new FormData();
      formData.append('file',file);

      const uploadParam = {
        userId: this.localUserId,
        type:1,
      };
      const json = JSON.stringify(uploadParam);
      const blob = new Blob([json], {type: 'application/json'});
      formData.append('backgroundImage', blob);

      try {
        const resData = await qureyImgUpload(formData)
        this.customUploadBgList.push(
          {
              id: resData.id,
              src: resData.uploadUrl
          }
        )
      } catch (error) {
          loganLog('uploadImgFn error',error)
      }

    },
    beforeUpload (file) {
      const fileSzie = file.size / 1024 / 1024;
      // 图片文件大小判断
      if (fileSzie >= 20) {
          this.$totast('文件过大，请上传20MB以内的图片文件')
          return false
      }
    },

    onCheckedVideoImage() {
      // TODO:
    },

    // 摄像头设备选择 select change事件监听
    onCameraSelectChange(value){
    },

    // 高清画质check-box change事件监听
    onCheckedVideoQuality() {
      setLocalDeviceInfo({
        videoQuality: this.myVideoQuality ? 1 : 0,
        videoResolution: this.myVideoQuality ? this.videoResolution : 0,
      });

      // 各摄像头高清选中状态标记
      if(this.currentVideoDevice && this.currentVideoDevice?.deviceId){
        if(this.myVideoQuality){
          this.cameraDeviceHighDefinition[this.currentVideoDevice.deviceId] = true;
        }else{
          for (const key in this.cameraDeviceHighDefinition) {
            if (Object.hasOwnProperty.call(this.cameraDeviceHighDefinition, key)) {
              this.cameraDeviceHighDefinition[key] = false;
            }
          }
        }
      }

      if(this.myVideoQuality){
        if(this.videoResolution == 1080){
          this.videoBitrates =  this.videoBitrate3
          this.currentSelectedVideoBitrate = 2000
          this.currentSelectedVideoFrame = 15
          setLocalDeviceInfo({
            videoBitrateValue: 2000,
            videoLimitValue: 15
          });
        }else{
          this.videoBitrates =  this.videoBitrate2
          this.currentSelectedVideoBitrate = 1200
          this.currentSelectedVideoFrame = 15
          setLocalDeviceInfo({
            videoBitrateValue: 1200,
            videoLimitValue: 15
          });
        }
      }else{
        this.videoBitrates =  this.videoBitrate1
        this.currentSelectedVideoBitrate = 550
        this.currentSelectedVideoFrame = 15
        setLocalDeviceInfo({
          videoBitrateValue: 550,
          videoLimitValue: 15
        });
      }

      // 重新推流
      this._resetPushStream()
    },

    // 启用编码参数check-box change事件监听
    onCheckedVideoEncoding() {
      setLocalDeviceInfo({
        videoEncoding: this.myVideoEncoding ? 1 : 0,
      });

      // 重新推流
      this._resetPushStream()
    },
    

    // 分辨率切换监听事件
    onCheckedVideoResolution: debounce(function() {
      setLocalDeviceInfo({
        videoResolution: this.videoResolution,
      });

      if(this.videoResolution == 1080){
        this.videoBitrates =  this.videoBitrate3
        this.currentSelectedVideoBitrate = 2000
        this.currentSelectedVideoFrame = 15
        setLocalDeviceInfo({
          videoBitrateValue: 2000,
          videoLimitValue: 15
        });
      }else{
        this.videoBitrates =  this.videoBitrate2
        this.currentSelectedVideoBitrate = 1200
        this.currentSelectedVideoFrame = 15
        setLocalDeviceInfo({
          videoBitrateValue: 1200,
          videoLimitValue: 15
        });
      }



      // 重新推流
      this._resetPushStream()
    }, 1000),

    _resetPushStream: debounce(async function() {
      let video = document.getElementById('settingVideo');
      await this.$deviceControl.startLocalPreviewOnly(this.currentVideoDevice.deviceId,video)


      return  // 频繁切换打开会黑屏 先改成下次开启生效 后面研究一下黑屏问题

      let userList = this.$store.getters["member/getRealUserList"];
      let currentUserPeerId =  this.$configs.peerId;

      // 判断当前是否开启摄像头
      let currentUserInfo = userList.find(i=> i.userId === currentUserPeerId);

      if(currentUserInfo && currentUserInfo?.isUseShiPin){

        try {

          // 停止推流
          await this.$meetingControl.stopLocalPreview();

          // 重新推流
          const videoEl = document.getElementById(`video-${currentUserPeerId}`);
          if(videoEl) this.$meetingControl.startLocalPreview(videoEl);

          this.startSettingVideoView(this.currentVideoDevice.deviceId);

          // 本端画面锁定显示
          this.mainPreviewLockShowSelf(userList);

        } catch (error) {
          loganLog(`[components serring Setting.vue] 切换分辨率推流失败----Error: ${JSON.stringify(error)}`);
          this.$totast(this.$t('meeting.operationFailed'))
        }
      }
    },500),

    // 本端画面锁定显示--判断大画面中是否锁定的自己
    mainPreviewLockShowSelf(userList) {
      let lockFramesUser = userList.find(i=> i.isDbClick); // 双击画面锁定用户信息

      if(lockFramesUser && lockFramesUser.isUseShiPin && lockFramesUser.userId === this.$configs.peerId) {

        const videoEl = document.getElementById(`main-video-${this.$configs.peerId}`);

        videoEl && (videoEl.srcObject = null);

        this.$meetingControl.startLocalPreview(videoEl);

        this.$meetingControl.changeMainLoadingClass();
      }
    },

    onCheckedVideoCodeModule() {
      setLocalDeviceInfo({
        videoCodeModule: this.videoCodeModule
      });
      // 重新推流
      this._resetPushStream()
    },
    onCheckedBeautLeave() {
      if(this.videoBeautLeave == 4){
        this.$deviceControl.setBeautyLevel(0)
        this.foreachRunBeaut(false)
      }else{
        this.$deviceControl.setBeautyLevel(this.videoBeautLeave)
      }
      setLocalDeviceInfo({
        videoBeautLeave: this.videoBeautLeave ? this.videoBeautLeave : 0,
      });
    },
    //音频设置
    loadAudioSettng() {
      //麦克风设备
      //音量
      this.$deviceControl.initMicDeviceVolume(
        this.isMeeting && this.isUseHuaTong
      );

      //扬声器设备
      //音量
      this.$deviceControl.initPlayoutVolume(
        null,
        this.isMeeting && this.isUseYangShengQi
      );

      //麦克风测试
      this.$deviceControl.enableMicTest();

      //检测麦克风音量值
      const that = this;
      if (that.micTestVolumeInterval) {
        clearInterval(that.micTestVolumeInterval);
        this.micTestVolumeInterval = null;
      }
      this.micTestVolumeInterval = setInterval(async () => {
        try {
          const volume = await that.$deviceControl.getMicTestVolume();
          that.currentHuaTongPercent = volume.toFixed(2);
        } catch (error) {
          loganLog("loadAudioSettng error:", JSON.stringify(error));
        }
      }, 500);
    },
    //网络检测
    async startNetworkDetection() {
      this.networkLoading = true;
      // 1.网络连接情况
      this.currentNetworkProgressValue = 1;
      const local = await ping(NETWORK_TEST_URL, 0.3);
      this.localNetworkTime = local.pingTime;

      // 2.服务器连接 + 连接耗时
      const server_url =
        "https://" + signalUrl.replace("/signal-server", "") + "/test/test.png";
      this.currentNetworkProgressValue = 2;
      const server = await ping(server_url, 0.3);
      this.serverNetworkTime = server.pingTime;
      setTimeout(() => {
        this.currentNetworkProgressValue = 3;
      }, 1000);
    },
    //检测扬声器
    async onTestMySpeaker() {
      if (!this.beginTestSpeaker) {
        this.beginTestSpeaker = true;
        // console.error(1111112,this.currentSpeakerDevice)
        if (this.currentSpeakerDevice && this.currentSpeakerDevice.deviceId) {
          await this.$refs["playoutAudio"].setSinkId(
            this.currentSpeakerDevice.deviceId
          );
        }
        if (Number.isInteger(this.speakerVolumeValue)) {
          this.$refs["playoutAudio"].volume = 0.01 * this.speakerVolumeValue;
        }

        this.$refs["playoutAudio"].play();
      } else {
        this.$refs["playoutAudio"].pause();
        this.beginTestSpeaker = false;
      }
    },
    destroyMicroTest() {
      if (this.micTestVolumeInterval) {
        clearInterval(this.micTestVolumeInterval);
        this.micTestVolumeInterval = null;
      }
      if (this.$deviceControl) {
        this.$deviceControl.disableMicTest();
      }
    },
    openNetworkWin() {
      this.$emit("openNetwork");
    },
    async getStats() {
      //TODO:质量检测
    },

    async getbeautBgList() {
      //获取背景图
      const localDeviceData = await getLocalDeviceInfo();
      this.localUserId = this.$configs.userId ? this.$configs.userId : getlocalUserId()    
      // 获取通用虚拟背景列表
      try {
        // if(this.$route.query.conferenceId) reqData.conferenceId =  this.$route.query.conferenceId
        // if(this.$configs.conferenceNo) reqData.conferenceNo = this.$configs.conferenceNo
        // if(this.$configs.userId) reqData.userId = this.$configs.userId
        // if(!this.$configs.userId) reqData.type = 0
        let localBgSrc = ''
        let localBgId = localDeviceData.arBgId == 'blur' ? 'blur':'transparent'
        const res = await getTentantArVirList({
          param:{
            type: 0
          },
          pageNum:1,
          pageSize:50
        });
        
        if(!res.records?.length) throw(`未读取到列表数据response: ${JSON.stringify(res.records || '{}')}`);
        let formatBgList = res.records.map(item=> {
            if(localDeviceData.arBgId == item.id) {
              localBgSrc = item.uploadUrl
              localBgId = item.id
            }
            return {
                id: item.id,
                src: item.uploadUrl
            };
        });
        this.systemArBgList = formatBgList;
        if(!this.localUserId){
          setTimeout(() => {
            let type = localBgId !== 'transparent' && localBgId !== 'blur' ? 'image' : localBgId;
            this.hadleSetArBg({
              type,
              id: localBgId,
              src:localBgSrc
            })
          }, 500);
          return
        } 

        //自己上传的
        const local_res = await getTentantArVirList({
          param:{
            type: 1,
            userId: this.localUserId
          },
          pageNum:1,
          pageSize:50
        });
        local_res.records.map(item=> {

          if(localDeviceData.arBgId == item.id){
            localBgSrc = item.uploadUrl
            localBgId = item.id
          } 
          this.customUploadBgList.push(
            {
                id: item.id,
                src: item.uploadUrl
            }
          )
        });
        if (localDeviceData.hasOwnProperty("arBgId")) { //人像码率
            // localDeviceData.arBgId;
            let type = localBgId !== 'transparent' && localBgId !== 'blur' ? 'image' : localBgId;
            if(type == 'image' && !localBgSrc) return
            setTimeout(() => {
              this.hadleSetArBg({
                type,
                id: localBgId,
                src:localBgSrc
              })
            }, 500);
        }

      } catch (error) {
          console.error('获取通用虚拟背景列表失败Error:', error);
      }
    },
  },
  async created() {
    this.browser = getBrowser()
    const localDeviceData = await getLocalDeviceInfo();

    if(this._gpuLevel != 'low'){
      setTimeout(() => {
        this.getbeautBgList()
      }, 1000);
    }

    if (localDeviceData.hasOwnProperty("shareLimitValue")) { //共享帧率
      this.currentSelectedShareFrame = Number(localDeviceData.shareLimitValue);
    } else {
      setLocalDeviceInfo({
        shareLimitValue: MAX_SHARE_VIDEO_FPS,
      });
    }

    if (localDeviceData.hasOwnProperty("videoEncoding")) { //共享帧率
      this.myVideoEncoding = localDeviceData.videoEncoding ? true : false
    } else {
      setLocalDeviceInfo({
        videoEncoding: false,
      });
    }


    if (localDeviceData.hasOwnProperty("videoLimitValue")) { //人像帧率
      if(localDeviceData.hasOwnProperty("videoEncoding")){
        this.currentSelectedVideoFrame = Number(localDeviceData.videoLimitValue);
      }
    } else {
      setLocalDeviceInfo({
        videoLimitValue: 15, //默认值
      });
    }

    
    if (localDeviceData.hasOwnProperty("shareResolutiontValue")) {
      this.currentSelectedResolution = localDeviceData.shareResolutiontValue;
      this.shareBitrates = localDeviceData.shareResolutiontValue == "1280x720" ? this.shareBitrate1 : localDeviceData.shareResolutiontValue == "1920x1080" ? this.shareBitrate2 : this.shareBitrate3
    } else {
      setLocalDeviceInfo({
        shareResolutiontValue: "2560x1440"
      });
    }
    if (localDeviceData.hasOwnProperty("shareBitrateValue")) { //共享码率
      this.currentSelectedShareBitrate = Number(localDeviceData.shareBitrateValue);
    } else {
      setLocalDeviceInfo({
        shareBitrateValue: 3000, //默认值
      });
    }

    if (localDeviceData.hasOwnProperty("videoBitrateValue")) { //人像码率
      if(localDeviceData.hasOwnProperty("videoEncoding")){
        this.currentSelectedVideoBitrate = Number(localDeviceData.videoBitrateValue);
      }
    } else {
      setLocalDeviceInfo({
        videoBitrateValue: 550, //默认值
      });
    }
  }
};
</script>

<style lang="scss">
::v-deep .el-checkbox__label {
  font-size: 14px !important;
}
::v-deep .el-checkbox,
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  font-family: "iconfont" !important;
  content: "\e6f2" !important;
}
::v-deep .el-button--default {
  height: 36px;
  padding: 0 10px;
  font-size: 14px;
  color: #fff;
  background-color: #1ab370;
  border-radius: 4px;
  &:hover {
    background: #3bbf82;
  }
  &:focus {
    background: #0e8c5a;
  }
}

.switch-warp{
  padding: 6px 8px;
  position: absolute;
  left: 50px;
  top: 22px;
  background: #FFFFFF;
  border-radius: 14px;
  opacity: 0.6;
}
.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*打开时文字位置设置*/
.switch .el-switch__label--right {
  z-index: 1;
  margin-left: 0;
}


/* 调整打开时文字的显示位子 */
.switch .el-switch__label--right span {
  color: #0e8c5a;
  font-size: 12px;
}

/*关闭时文字位置设置*/
.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
.switch .el-switch__label--left span {
  color: #333333;
  font-size: 12px;
}

/*显示文字*/
.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 32px;
}
.switch.el-switch .el-switch__core{
  margin-left: 30px;
}

.radio-box {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 14px;
  .radio-item {
    display: flex;
    align-items: center;
    width:210px;
    height: 24px;
    font-size: 12px;
    font-weight: normal;
    color: #000000;
  }
  .argumentsItem{
    margin-top: 10px;
    display: flex;
    p{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin: 0 10px;
      vertical-align: top;
      span{
        display: inline-block;
        min-width: 60px;
        text-align-last: justify;
      }
    }
  }
}

.common-set-line {
  display: flex;
  align-items: center;

  &.lang {
    margin-top: 6px;

    .lang-text {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    .lang-select {
      width: 100px;
    }
    .lang-select-box {
      width: 100px;
    }
  }
}
.shareArguments{
  display: block;
  .shareArgumentsSet{
    display: block;
    padding-left: 100px;
    .argumentsItem{
      margin-top: 10px;
      display: flex;
      p{
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        margin: 0 10px;
        vertical-align: top;
        span{
          display: inline-block;
          min-width: 60px;
          text-align-last: justify;
        }
      }
    }
  }
}

.slider-box {
  display: flex;
  align-items: center;

  width: 321px;

  .slider {
    flex-grow: 1;
  }

  .slider-text {
    // width: 50px;
    // background: red;
    margin-left: 10px;
    font-size: 14px;
  }
}

.userSelect {
  width: 320px;
}
.userMinSelect {
  width: 160px;
  margin-left: -8px;
  vertical-align: top;
}

.setting-container {
  height: 530px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  box-sizing: border-box;

  .setting-header {
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    box-sizing: border-box;

    .setting-title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 48px;
    }

    .setting-close {
      position: absolute;
      top: -32px;
      right: 16px;
      cursor: pointer;
    }
  }

  .setting-body {
    display: flex;
    height: calc(100% - 48px);
    box-sizing: border-box;
    .setting-nav {
      width: 128px;
      box-sizing: border-box;
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      padding-top: 16px;
      transition: all 0.2s ease;

      .setting-menu {
        .setting-item {
          box-sizing: border-box;
          padding: 8px 0 8px 24px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          cursor: pointer;

          &:hover {
            background: rgba(0, 0, 0, 0.06);
          }
        }

        .setting-item.active {
          color: #ffffff;
          background: #1ab370;
        }
      }
    }
    .seeting-width {
      width: 142px;
    }

    .setting-content {
      flex: 1 1 auto;
      box-sizing: border-box;
      overflow: scroll;
      position: relative;
      .video-view{
        position: absolute;
        top:0;
        left: 0;
        width: 416px;
        height: 234px;
        margin-top: 16px;
        margin-left: 0;
        margin-left: 48px;
      }
      .text-hide{
        .label {
          display: none !important;
        }
      }
      .setting-normal {
        padding: 24px 0 0 48px;

        .single-option {
          display: block;
          width: 100%;
        }
        .single-option-s2 {
          margin-bottom: 10px;
        }

        .setting-normal-item {
          overflow: hidden;
          margin-top: 8px;

          ::v-deep .input__word {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }

          &:first-child {
            margin-top: 0;
          }
        }

        .setting-frame-unit {
          display: inline-block;
        }

        .setting-frame {
          display: inline-block;
        }
      }

      .setting-feedback {
        padding: 32px 48px 0 48px;

        .feedback-title {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
        .feedback-contact {
          background: rgba(255, 255, 255, 0.6);
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          margin-top: 18px;
          padding: 0px 0 0px 16px;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          .feedback-contact-title {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }

          .contact-form {
            ::v-deep .input-box-border {
              border: none;
            }
          }
        }

        .feedback-button {
          display: flex;
          justify-content: space-between;

          .telephone {
            margin-top: 46px;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }

          .submit {
            margin-top: 38px;
          }
        }
      }

      .setting-network {
        position: relative;
        box-sizing: border-box;
        height: 100%;

        .network-test {
          padding-top: 80px;
          text-align: center;

          .network-icon {
            width: 64px;
            height: 64px;
            display: inline-block;
          }

          .network-title {
            font-size: 20px;
            margin-top: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
          }

          .network-subtitle {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
            margin-top: 6px;
          }

          .network-button {
            margin-top: 18px;
            /* ::v-deep button {
                height: 36px;
                padding:0 10px;
            }
            ::v-deep .el-icon-loading {
               font-size: 16px;
            } */
          }
        }

        .network-result {
          box-sizing: border-box;
          padding: 0 24px;

          .status-button {
            ::v-deep button {
              padding: 0 10px;
              height: 36px;
            }
            ::v-deep .el-icon-loading {
              font-size: 16px;
            }
          }

          .network-result-header {
            display: flex;
            box-sizing: border-box;
            padding: 24px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.04);

            .status-icon {
              width: 48px;
              height: 48px;
            }

            .status-content {
              flex: 1 1 auto;
              box-sizing: border-box;
              padding-left: 10px;

              .status-title {
                font-size: 16px;
                font-weight: 400;
                color: #000000;
                line-height: 18px;
              }

              .status-sub-title {
                margin-top: 6px;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
              }
            }
          }

          .network-result-status {
            padding-top: 30px;
            box-sizing: border-box;

            .result-item {
              display: flex;
              justify-content: space-between;
              margin-top: 16px;

              .result-name {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
              }

              .result-status {
                font-size: 14px;
                font-weight: 400;
                color: #1ab370;
                line-height: 20px;
              }
              .error {
                color: #ff4d4f;
              }

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }

      .setting-quality {
        position: relative;
        box-sizing: border-box;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .panel {
          width: 472px;
          height: 104px;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          margin-top: 11px;
          margin-left: 16px;
        }
        .panel:nth-child(-n + 2) .body {
          padding-left: 29px;
        }
        .panel:nth-child(n + 3) .body {
          padding-left: 16px;
        }
        .panel .item.bottom {
          margin-left: 36px;
        }
        .panel:nth-child(n + 3) .box:nth-child(1) .item.bottom,
        .panel:nth-child(1) .box:nth-child(2) .item.bottom {
          margin-left: 49px;
        }

        .body {
          display: flex;
          padding-top: 12px;
          justify-content: space-between;
          width: 100%;
        }
        .box {
          width: 33.3%;
          .item {
            width: 100%;
            height: 18px;
            line-height: 18px;
            font-size: 13px;
            font-weight: 400;
            display: flex;
            .before {
              color: #999999;
              margin-right: 10px;
            }
            .after {
              color: #333333;
              margin-left: 1px;
            }
          }
          .item.bottom {
            margin-top: 4px;
          }
        }
        .title {
          height: 30px;
          background: #f7f7f7;
          padding-left: 14px;
          position: relative;
          line-height: 30px;

          font-size: 13px;
          font-weight: 400;
          color: #666666;
          .desc {
            position: absolute;
            right: 10px;
            top: 0;
            font-size: 12px;
            color: #333333;
          }
        }
        .btn-area {
          width: 100%;
          height: 80px;
          position: absolute;
          margin-top: 30px;
          border-top: 1px solid #e5e5e5;
          button {
            position: absolute;
            top: 24px;
            right: 24px;
          }
        }
      }

      .setting-audio {
        padding: 0 48px;
        .audio {
          padding: 24px 0;
          border-bottom: 1px solid #e5e5e5;
          .micro {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
              font-size: 14px;
              font-weight: 400;
              color: #000000;
              line-height: 20px;
            }
            .micro-right {
              display: flex;
              justify-content: space-between;
              width: 320px;
              align-items: center;
            }
          }
          .audio-volume {
            margin-top: 22px;
            display: flex;
            justify-content: space-between;
            .title {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
            .volume-slider {
              margin-left: 60px;
            }
          }
          .audio-status {
            display: flex;
            justify-content: flex-end;
            .box {
              width: 320px;
              margin-top: 32px;
            }
            .status-show {
              margin-bottom: 12px;
            }
            .status-tips {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
            }
          }
          &:last-child {
            border-bottom: none;
          }
          ::v-deep .speaker-button {
            /* width: 100px; */
            height: 36px;
            padding: 0 10px;
            margin-left: 10px;
          }
        }
      }

      ::v-deep .selectInput .contentText,
      ::v-deep .selectInput .list .item {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .setting-beaut {
        .control-bar {
          width: 416px;
          height: 36px;
          position: absolute;
          top: 198px;
          background: rgba(0, 0, 0, 0.3);
          .slider {
            width: 280px;
            margin: auto;
          }
          .control-bar-number {
            position: absolute;
            right: 44px;
            top: 9px;
            color: #ffffff;
          }
        }
        .beaut-radio-warp{
          width: 416px;
          display: flex;
          margin-top: 16px;
          justify-content: space-between;
          .radio-item{
            width: 100px;
            height: 36px;
            line-height: 36px;
            padding-left: 12px;
            border-radius: 4px;
            margin: 0;
            box-sizing: border-box;
            font-size: 13px;
            border: 1px solid #E5E5E5;
            .el-radio__label{
              font-size: 13px;
              padding-left: 8px;
            }
          }
        }
        .beaue-diy-warp{
          margin-top: 20px;
          display: flex;
          position: absolute;
          top: 286px;
          li{
            text-align: center;
            width: 45px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 17px;
            margin-right: 17px;
            img{
              widows: 45px;
              height: 45px;;
              display: block;
              margin-bottom: 4px;
            }
          }
        }
        .resetConfirmMask{
          background-color: rgba(0, 0, 0, 0.3);
          width: 100%;
          height: 100%;
          position:fixed;
          top: 0;
          left: 0;
          z-index: 3000;
        }
        .resetConfirm{
          padding: 40px 40px;
          position: absolute;
          border-radius: 5px;
          top: 30%;
          left: 50px;
          background: #ffffff;
          z-index: 3001;
          .content{
            margin-bottom: 20px;
          }
        }
      }
      .setting-beautBg{
        padding: 248px 48px 0;
        .setting-beautBg-desc{
          width: 420px;;
          margin-top: 8px;
          font-size: 12px;
          color: #999999;
          span{
            color: #333333;
          }
        }
        .beautBg-container{
          width:420px;
          margin-top: 16px;
          overflow-y: scroll;
          .beautBg-box{
            display: flex;
            flex-wrap: wrap;
            .upload-demo{
              margin-left: 12px;
              width: 90px;
              height: 56px;    
              text-align: center;
              vertical-align: top;
              background-color: #fafafa;
              border: 1px dashed #d9d9d9;
              border-radius: 2px;
              cursor: pointer;
              transition: border-color 0.3s;
              .upload-submit{
                line-height: 56px;    
                .span{
                  position: relative;
                  top: -1px;
                }
              }
            }
            .user-upload-list{
              position: relative;
              width: 90px;
              height: 56px;
              border: 1px solid transparent;
              text-align: center;
              line-height: 56px;
              font-size: 12px;
              background-color: #F7F8F9;
              margin-left: 12px;
              margin-bottom: 10px;
              cursor: pointer;
              border-radius: 4px;
              overflow: hidden;
              .card{

              }
              .upload-bg-delete {
                  opacity: 0;
                  position: absolute;
                  right: 4px;
                  bottom: 4px;
                  padding: 3px;
                  background-color: rgba(0, 0, 0, .3);
                  border-radius: 2px;
                  width: 10px;
                  height: 10px;
                  display: inline-block;
              }
              .upload-bg-delete:hover {
                  background-color: rgba(0, 0, 0, .5);
              }

              &:hover{
                border: 1px solid #1AB370;
                .upload-bg-delete {
                  opacity: 1;
                }
              }
            }
            .active-arbg-item::after{
                content: '';
                width: 32px;
                height: 32px;
                position: absolute;
                right: -16px;
                top: -16px;
                border-radius: 50px;
                background-color: #1AB370;
                background-image: url(../../assets/images/beaut/duigou-white.png);
                background-repeat: no-repeat;
                background-size: 8px 8px;
                background-position: 6px 18px;
            }
          }
          .beautBg-h2{
            font-size: 12px;
            color: #000;
            margin-bottom: 6px;
          }
        }
      }
      .setting-video {
        padding: 248px 48px 0;
        position: relative;
        .note {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          margin-top: 8px;
        }
        .camera {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 18px;
          .title {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
          }
        }
        .my-video-title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          margin-top: 8px;
        }
        .code-selection {
          margin-top: 8px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
        .my-video-checkbox {
          width: 100%;
          .setting-normal-item {
            overflow: hidden;
            margin-top: 8px;

            ::v-deep .input__word {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
</style>